import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Tsn from "../images/logo.tsn.svg"
import Crave from "../images/logo.crave.svg"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Carousel from "../components/carousel";

const About = ({ data, location }) => {
    let global = data.contentfulWebsite;
    let cards = [
        {
            title: global.title,
            subtitle: data.contentfulWhereToWatch.title,
            url: "/trailer",
            buttonText: data.contentfulWhereToWatch.primaryDate,
            image: data.contentfulWhereToWatch.image,
            logo: data.contentfulWhereToWatch.logo.resize.src,
        }
    ]
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content o-content-container o-content-container--large u-text-center">
                <Carousel slides={cards}></Carousel>
                <h1>{data.contentfulWhereToWatch.datesTitle}</h1>
                {data.contentfulWhereToWatch.showings.map((item, index) => (
                    <div className="c-showing">{item.title} | {item.subtitle}</div>
                ))}
                <div className="o-child-spacer u-flex-center u-flex-wrap">
                    <Link className="c-logo c-logo--xlarge" to="https://www.tsn.ca/" target="_blank" rel="noreferrer">
                        <img src={Tsn} alt="TSN" />
                    </Link>
                    <Link className="c-logo c-logo--xlarge" to="https://www.crave.ca/" target="_blank" rel="noreferrer">
                        <img src={Crave} alt="Crave" />
                    </Link>
                </div>
                <div className="c-showing">International distribution inquiries @ <a href="mailto:socialcanada@uninterrupted.com">socialcanada@uninterrupted.com</a></div>
            </div>
        </Layout>
    );
};

export default About

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
    contentfulWhereToWatch {
        image {
            resize(width: 1900) {
                src
            }
        }
        logo {
            resize(width: 200) {
                src
            }
        }
        primaryDate
        title
        datesTitle
        showings {
            title
            subtitle
        }
    }
}
`;
